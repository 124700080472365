import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://dffaf96864d2ce1c8aa29e9fd42b3d55@o4506337258831872.ingest.us.sentry.io/4506337281900544",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.2,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});